<template>
  <v-container>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          :textTitle="endPointName"
          :textLinesArray="endpointDesc"
        ></TextArea>
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <EndpointCard cardTitle="ENDPOINTS" :endpoints="endpoints" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <Attribute :attributes="attributes" :endpoint="responseTitle" />
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <ResponseCard :response="response" :cardTitle="responseCodeTitle" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0"> </v-row>
  </v-container>
</template>
<script>
import EndpointCard from "../../components/content/EndpointCard.vue";
import Attribute from "../../components/request/Attribute.vue";
import TextArea from "../../components/content/TextArea.vue";
import ResponseCard from "../../components/request/ResponseCard.vue";

const endPointName = "ProjectGroups";
const ekURL = "/Projects/ProjectGroups";

export default {
  components: {
    EndpointCard,
    Attribute,
    TextArea,
    ResponseCard,
  },
  data() {
    return {
      responseTitle: `The ${endPointName} Object`,
      responseCodeTitle: `${endPointName.toUpperCase()} RESPONSE. E-KOMPLET URL: ${ekURL}`,
      endPointName: endPointName,
      endpointDesc: [
        "TODO"
      ],
      endpoints: [
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}`,
        },
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}/:id`,
        },
      ],
      response: [
        {
          Select: true,
          ID: 1,
          Name: "Sprinkler Spjæld",
          Description: "Sprinkler Spjæld",
          TypeID: "MiniProject",
          IsIntern: false,
          IsActive: false,
          IsComplaint: false,
          IsWorkInProgress: true,
          IsMobileDefault: false,
          BlockHourRegistrations: false,
          CoverageInPercent: 30.0,
          FinancialAccountPurchaseID: null,
          FinancialAccountPurchaseName: null,
          FinancialAccountSaleID: null,
          FinancialAccountSaleName: null,
          SelectedCount: 1,
          TemplateString:
            "121",
          TemplateStringToSave: null,
          Actions: null,
        },
      ],
      attributes: [
        {
          name: "Select",
          desc: "Internal value.",
        },
        {
          name: "ID",
          desc: "Unique identifier of the project group.",
        },
        {
          name: "Name",
          desc: "Name of the project group.",
        },
        {
          name: "Description",
          desc: "Description of the project group.",
        },
        {
          name: "TypeID",
          desc: "Unique identifier of the type of project.",
        },
        {
          name: "IsIntern",
          desc: "",
        },
        {
          name: "IsActive",
          desc: "",
        },
        {
          name: "IsComplaint",
          desc: "",
        },
        {
          name: "IsworkInProgress",
          desc: "",
        },
        {
          name: "IsMobileDefault",
          desc: "",
        },
        {
          name: "BlokHourRegistrations",
          desc: "",
        },
        {
          name: "CoverageInPercent",
          desc: "",
        },
        {
          name: "FinancialAccountPurchaseID",
          desc: "",
        },
        {
          name: "FinancialAccountPurchaseName",
          desc: "",
        },
        {
          name: "FinancialSaleID",
          desc: "",
        },
        {
          name: "FinancialAccountSaleName",
          desc: "",
        },
        {
          name: "SelectedCount",
          desc: "",
        },
        {
          name: "TemplateString",
          desc: "",
        },
        {
          name: "TemplatStringToSave",
          desc: "",
        },
        {
          name: "Actions",
          desc: "",
        },
      ],
    };
  },
};
</script>
<style scoped>
</style>