<template>
  <v-container>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          :textTitle="endPointName"
          :textLinesArray="endpointDesc"
        ></TextArea>
        <TextArea
          :textTitle="filteringTitle"
          :textLinesArray="filteringText"
        ></TextArea>
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <EndpointCard cardTitle="ENDPOINTS" :endpoints="endpoints" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <Attribute :attributes="attributes" :endpoint="responseTitle" />
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <ResponseCard :response="response" :cardTitle="responseCodeTitle" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0"> </v-row>
  </v-container>
</template>
<script>
import EndpointCard from "../../components/content/EndpointCard.vue";
import Attribute from "../../components/request/Attribute.vue";
import TextArea from "../../components/content/TextArea.vue";
import ResponseCard from "../../components/request/ResponseCard.vue";

const endPointName = "Budgets";
const ekURL = "/Accountancy/Budget";

export default {
  components: {
    EndpointCard,
    Attribute,
    TextArea,
    ResponseCard,
  },
  data() {
    return {
      filteringTitle: "Filtrering",
      filteringText: ["På dette endpoint kan man påføre filtrering med parameterne, <code>fromDate</code> og <code>toDate</code>."],
      responseTitle: `The ${endPointName} Object`,
      responseCodeTitle: `${endPointName.toUpperCase()} RESPONSE. E-KOMPLET URL: ${ekURL}`,
      endPointName: endPointName,
      endpointDesc: [
      ],
      endpoints: [
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}`,
        },
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}/:id`,
        },
      ],
      response: [
{
"ID": 201,
"FinAccountID": 7,
"FinancialAccountNumber": 20,
"FinancialAccountName": "Udført arbejde",
"Description": "Udført arb.",
"StartDate": "2016-12-31T23:00:00Z",
"EndDate": "2017-01-30T23:00:00Z",
"Amount": 100000.0,
"DimensionCarrierID": null,
"DimensionDepartmentID": null,
"DimensionPurposeID": null,
"DimensionCarrierName": null,
"DimensionDepartmentName": null,
"DimensionPurposeName": null,
"MonthSplit": false,
"Actions": null
}
      ],
      attributes: [
        {
          name: "Select",
          type: "boolean",
          desc: "Internal property.",
          default: "true",
        },
      ],
    };
  },
};
</script>
<style scoped>
.text-area {
  padding-right: 10%;
}
.left-column {
  margin-left: 12%;
}
.right-column {
  margin-right: 12%;
}
</style>