import { render, staticRenderFns } from "./EndpointsForCustomer.vue?vue&type=template&id=b8c950a6&scoped=true&"
import script from "./EndpointsForCustomer.vue?vue&type=script&lang=js&"
export * from "./EndpointsForCustomer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8c950a6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VDataTable,VIcon,VList,VNavigationDrawer,VTextField})
