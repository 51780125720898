<template>
  <v-container>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          :textTitle="endPointName"
          :textLinesArray="endpointDesc"
        ></TextArea>
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <EndpointCard cardTitle="ENDPOINTS" :endpoints="endpoints" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <Attribute :attributes="attributes" :endpoint="responseTitle" />
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <ResponseCard :response="response" :cardTitle="responseCodeTitle" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0"> </v-row>
  </v-container>
</template>
<script>
import EndpointCard from "../../components/content/EndpointCard.vue";
import Attribute from "../../components/request/Attribute.vue";
import TextArea from "../../components/content/TextArea.vue";
import ResponseCard from "../../components/request/ResponseCard.vue";

const endPointName = "ProfitGroups";
const ekURL = "/Invoice/ProfitGroupSettings";

export default {
  components: {
    EndpointCard,
    Attribute,
    TextArea,
    ResponseCard,
  },
  data() {
    return {
      responseTitle: `The ${endPointName} Object`,
      responseCodeTitle: `${endPointName.toUpperCase()} RESPONSE. E-KOMPLET URL: ${ekURL}`,
      endPointName: endPointName,
      endpointDesc: ["TODO"],
      endpoints: [
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}`,
        },
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}/:id`,
        },
      ],
      response: [
        {
          ID: 4,
          Name: "25% i rabat på ALT",
          IsActive: true,
          Actions: null,
        },
      ],
      attributes: [
        {
          name: "ID",
          desc: "Unique identifier of the profit group.",
        },
        {
          name: "Name",
          desc: "Name of the profit group.",
        },
      ],
    };
  },
};
</script>
<style scoped>
</style>